import { useRef, useState } from "react";

const useInfiniteSearchToTop = () => {
  const [page, setPage] = useState(1);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const containerRef = useRef(null);

  /**
   * Handles scroll events on the container element.
   *
   * This function triggers pagination when scrolled to the top and toggles
   * the visibility of a "scroll to bottom" button based on the scroll position.
   */
  const onScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Trigger pagination if scrolled to the very top
      if (scrollTop === 0) {
        setPage((prev) => prev + 1);
      }

      // Show the "scroll to bottom" button if not near the bottom
      if (scrollTop + clientHeight < scrollHeight - 100) {
        setShowScrollToBottom(true);
      } else {
        setShowScrollToBottom(false);
      }
    }
  };

  return {
    onScroll,
    containerRef,
    page,
    showScrollToBottom,
  };
};

export default useInfiniteSearchToTop;
