import React from "react";
import ExlyBaseImage from "@my-scoot/exly-react-component-lib/build/esm/features/Common/modules/ExlyImage/ExlyImage";
import { IMAGE_OPTIMIZATION_CDN_URL } from "./constants/ExlyImage.constants";

const ExlyImage = ({ alt = "some-exly-image", ...restProps }) => {
  return (
    <ExlyBaseImage
      cdnBaseUrl={IMAGE_OPTIMIZATION_CDN_URL}
      alt={alt}
      {...restProps}
    />
  );
};
export default ExlyImage;
