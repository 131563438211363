import { generateRandomString } from "utils/Utils";
import { ACCEPTED_IMAGES_FORMAT_IN_OPTIMIZATING_CDN } from "../constants/ExlyImage.constants";
import { getAuthData } from "utils/AuthUtil";
import { parseFileNameForUploadOrDownload } from "utils/fileUtils";
import {
  IMAGE_OPTIMIZATION_CDN_URL,
  IMAGE_OPTIMIZATION_S3_BUCKET,
  NON_OPTIMIZATION_CDN_URL,
  NON_OPTIMIZATION_S3_BUCKET,
} from "../constants/ExlyImage.constants";
import { uploadToS3 } from "utils/Utils";
import constants from "constants/constants";
import { logError } from "utils/error";

export const isImageTypeValidForOptimization = (imageType) =>
  ACCEPTED_IMAGES_FORMAT_IN_OPTIMIZATING_CDN.includes(imageType);

export const generateS3ImageKey = (fileName, section) => {
  const username = getAuthData().username;
  const s3ImageName =
    generateRandomString(3) +
    "_" +
    Date.now() +
    "_" +
    parseFileNameForUploadOrDownload(fileName);
  return `exly_images/${username}/${section}/${s3ImageName}`;
};

/**
 * removes data:image/jpg;base64 from data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAA...
 * and process as buffer to successfully upload to s3.
 * This function to be used in case we are cropping images and we need to upload b64 image string.
 */
export const preProcessB64ImageForS3Upload = (b64ImageString) =>
  Buffer.from(b64ImageString.split(",")[1], "base64");

export const appendKeyToCdnUrl = (cdnBaseUrl, key) => `${cdnBaseUrl}/${key}`;

/**
 * Use this function to upload all images which serves images through public cdn's and
 * return exly's public cdn urls which can futher used to fetch optimized images based on buckets uploaded.
 *
 * @param fileType should be the file mime type
 */
export const uploadImageToExlyBucket = async ({
  fileName,
  fileType,
  file,
  sectionName,
}) => {
  if (!fileName || !fileType || !file || !sectionName) {
    throw new Error(
      "some arguments out of (fileName, fileType, file, sectionName) are falsy."
    );
  }

  try {
    const key = generateS3ImageKey(fileName, sectionName);

    const isImageValidForOptimization =
      isImageTypeValidForOptimization(fileType);

    const bucketName = isImageValidForOptimization
      ? IMAGE_OPTIMIZATION_S3_BUCKET
      : NON_OPTIMIZATION_S3_BUCKET;

    await uploadToS3({
      file: file,
      key: key,
      contentType: fileType,
      bucket: bucketName,
      AWSConfig: constants.RECORDED_CONTENT_UPLOAD_AWS_CRED,
    });

    const cdnUrl = isImageValidForOptimization
      ? IMAGE_OPTIMIZATION_CDN_URL
      : NON_OPTIMIZATION_CDN_URL;

    return appendKeyToCdnUrl(cdnUrl, key);
  } catch (error) {
    logError({
      error: error?.body?.message,
      occuredAt:
        "uploadImageToExlyBucket in src/features/Common/modules/Image/Images.utils.js",
      when: "Error while uploading image to exly buckets",
    });
  }
};
