import { is_empty as _is_empty } from "utils/validations";

/**
 * Smoothly scrolls the page to the element with the specified ID.
 *
 * @param {string} elementId - The ID of the element to scroll to.
 This function scrolls to the target element using the `scrollIntoView` method with smooth scrolling behavior.
 *
 */
export const onScrollToDiv = ({ elementId }) => {
  setTimeout(() => {
    const divElement = document.getElementById(elementId);
    divElement && divElement.scrollIntoView({ behavior: "smooth" });
  }, 10);
};

export const is_empty = _is_empty;
