import { dataProvider } from "data";
import { UPDATE_CREATOR_DATA_API } from "../CreatorData.constants";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { logError } from "utils/error";
import { getAuthData, setAuthData } from "utils/AuthUtil";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { is_empty } from "utils/validations";

export const useCreatorData = () => {
  const { notify } = useNotifications();

  const updateUserData = async ({
    updatedValues,
    onError,
    onFinally,
    successMessage,
    errorMessage,
  }) => {
    try {
      const authData = getUserData();
      const response = await dataProvider.custom_request(
        `${UPDATE_CREATOR_DATA_API}/${authData.username}`,
        apiMethods.POST,
        { updated_values: updatedValues }
      );
      if (isRequestSuccessful(response.status)) {
        setAuthData(Object.assign(authData, updatedValues));
        notify(
          successMessage ?? response.message,
          notification_color_keys.success
        );
      }
    } catch (error) {
      logError({
        error,
        when: "updateUserData",
        occuredAt:
          "src/features/Common/modules/UserData/utils/useCreatorData.js",
      });
      if (onError) onError(error);
      notify(
        errorMessage ?? error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      if (onFinally) onFinally();
    }
  };

  const getUserData = (key = "") => {
    const authData = getAuthData();
    return !is_empty(key) && key in authData ? authData[key] : authData;
  };

  return {
    updateUserData,
    getUserData,
  };
};
